<template>
    <v-container fluid>
        <PageLayout>
            <template v-slot:side>
                <v-icon color="secondary" @click="filter_show===true ? filter_show=false : filter_show=true">mdi-filter-plus</v-icon>
                <v-list class="filter_left mt-5">
                    <v-list-group :ripple="false" v-model="group1" :group="'group1'" class="px-0">
                        <template v-slot:activator class="px-0">
                            <v-list-item-title :ripple="false" class="main_filter">{{$t('system_filters')}}</v-list-item-title>
                        </template>

                        <v-list-item :ripple="false" v-for="(item, i) in system_filters" :key="i" link>
                            <v-list-item-title v-text="item.title"></v-list-item-title>
                            <v-list-item-icon v-text="item.count" class="filter_count"></v-list-item-icon>
                        </v-list-item>
                    </v-list-group>
                </v-list>

                <v-list class="filter_left">
                    <v-list-group :ripple="false" v-model="group2" :group="'group2'" class="px-0">
                        <template v-slot:activator class="px-0">
                            <v-list-item-title :ripple="false" class="main_filter">{{$t('additional')}}</v-list-item-title>
                        </template>
                        <v-list-item :ripple="false" v-for="(item, i) in Additional" :key="i" link>
                            <v-list-item-title v-text="item.title"></v-list-item-title>
                            <v-list-item-icon v-text="item.count" class="filter_count"></v-list-item-icon>
                        </v-list-item>
                    </v-list-group>
                </v-list>
            </template>

            <template>
                <h1 class="mb-4">{{ filter_show ? $t('search_storage') : $t('storage') }}</h1>
                <v-row>
                    <v-col cols="12">
                        <v-form @submit.prevent="getStorages('search')">
                            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                                <v-row v-if="filter_show">
                                    <v-col cols="12" sm="8">
                                        <ValidationProvider ref="storage" rules="min:1|max:255" v-slot="{ errors, valid }">
                                            <v-text-field v-model="storage" type="text" :error-messages="errors"
                                                          :label="$t('name')"
                                                          autocomplete="off"
                                                          outlined
                                                          solo
                                                          flat
                                                          dense
                                                          :disabled="loading"
                                                          :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                          color="primary"
                                                          hide-details
                                                          class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                          clearable></v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-dialog ref="dateDialog" v-model="dateDialog" width="290px">
                                            <v-date-picker ref="pickerDateContract" v-model="date"
                                                           :locale="lang"
                                                           hide-details
                                                           @change="dateDialog = false">
                                            </v-date-picker>
                                        </v-dialog>
                                        <ValidationProvider ref="dateContract" rules="date_format" v-slot="{ errors, valid }">
                                            <v-text-field v-model="date" :error-messages="errors"
                                                          :error="!valid"
                                                          :label="$t('date_created')"
                                                          readonly
                                                          @click.stop="dateDialog = !dateDialog"
                                                          @click:clear="date = null"
                                                          autocomplete="off"
                                                          outlined
                                                          solo
                                                          hide-details
                                                          flat
                                                          dense
                                                          :disabled="loading"
                                                          :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                          color="primary"
                                                          class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                          clearable></v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                                <v-row  v-if="filter_show">
                                    <v-col cols="12" sm="3" md="2">
                                        <v-select :value="perPage" :items="perPageItems"
                                                  :label="$t('items_per_page')"
                                                  @input="perPage = options.itemsPerPage = Number($event)"
                                                  hide-details
                                                  autocomplete="off"
                                                  outlined
                                                  solo
                                                  flat
                                                  dense
                                                  :disabled="loading"
                                                  :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                  color="primary"
                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="9" md="10"
                                           class="d-flex justify-center justify-sm-end align-self-center">
                                        <v-btn type="submit" :disabled="invalid || loading" class="infinity_button"
                                               :block="$vuetify.breakpoint.xsOnly" color="primary">
                                            {{ $t('search') }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </ValidationObserver>
                        </v-form>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <ResizableTable
                            class="main-data-table"
                            :rows="storages"
                            :columns="filteredHeaders.map((item) => {
                            return {
                                ...item,
                                active: true,
                                prop: item.value,
                                label: item.text,
                                width: 'auto'
                            }
                        })"
                        >
                            <template v-slot:url="{ item }">
                                <div>
                                    <v-img  v-if="mimeTypeImage(item.type_mime)"
                                            :src="item.url"
                                            height="40" width="40"></v-img>

                                    <v-icon class="text-center"
                                            height="40" width="40"
                                            x-large
                                            v-if="!mimeTypeImage(item.type_mime)">mdi-file-document-outline mdi-24px</v-icon>
                                </div>
                            </template>

                            <template v-slot:created_at="{ item }">
                                <div>{{ $moment(item.created_at).format('DD.MM.YYYY HH:mm') }}</div>
                            </template>

                            <template v-slot:action="{ item }">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            :href="item.url"
                                            download
                                            target="_blank"
                                            icon>
                                            <v-icon>mdi-file-download-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{$t('download')}}</span>
                                </v-tooltip>
                            </template>
                        </ResizableTable>
                        <v-pagination v-model="page" :total-visible="totalVisiblePag" class="pagination_table" :length="pageCount" :disabled="loading"></v-pagination>
                    </v-col>
                </v-row>
            </template>
        </PageLayout>
    </v-container>
</template>
<script>

    import { mapGetters } from "vuex"
    import { ValidationObserver, ValidationProvider } from 'vee-validate'
    import debounce from "lodash/debounce";
    import FilterView from "@/plugins/mixins/FilterView";

    export default {
        name: "Storages",
        components: {
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                date: null,
                dateDialog: false,
                client: null,
                clientItems: [],
                clientSearching: null,
                loadingClients: false,
                storage: null,
                email: null,
                storages: [],
                storageItems: [],
                active: 1,
                activeItems: [
                    {
                        id: null,
                        name: this.$t('all')
                    },
                    {
                        id: 1,
                        name: this.$t('yes')
                    },
                    {
                        id: 0,
                        name: this.$t('no')
                    }
                ],
                sortBy: "id",
                sortDir: true,
                page: 1,
                pageCount: 0,
                perPage: 5,
                totalVisiblePag: 5,
                totalStorages: 0,
                options: {
                    ...FilterView.data().options,
                    sortBy: ['id'],
                },
                loading: false,
                loadingCities: false,
                city: null,
                cityItems: [],
                citySearching: null,
                headers: [
                    {
                        text: this.$t('review'),
                        align: "left",
                        sortable: true,
                        value: "url"
                    },
                    {
                        text: this.$t('name'),
                        align: "left",
                        sortable: false,
                        value: "name"
                    },
                    {
                        text: this.$t('author'),
                        align: "left",
                        sortable: true,
                        value: "author"
                    },
                    {
                        text: this.$t('date_created'),
                        align: "left",
                        sortable: true,
                        value: "created_at"
                    },
                ],
                system_filters: [
                    {'title': 'Все файлы', 'count': '10'},
                    {'title': 'Мои файлы', 'count': '0'},
                    {'title': 'Избранное', 'count': '7'},
                ],
                Additional: [
                    {'title': 'База Казахстан', 'count': '10'},
                    {'title': 'База Россия', 'count': '10'},
                    {'title': 'База Узбекистан', 'count': '10'},
                ],
                group1: true,
                group2: true,
                filter_show: false,
            };
        },
        computed: {
            ...mapGetters(['lang', 'itemsPerPage', 'perPageItems']),
            filteredHeaders() {
                return this.headers.filter(h => !h.hide)
            },
        },
        mounted() {
            this.options.itemsPerPage = this.perPage = this.itemsPerPage
            this.getStorages()
        },
        watch: {
            options: {
                handler() {
                    this.getStorages()
                },
                deep: false
            },
            clientSearching: debounce(function (val) {
                if (val && !this.client) {
                    this.getClients(val)
                }
            }, 500)
        },
        methods: {
            rowClass(){
                return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass' : 'rowMobiClass';
            },
            async getStorages(type) {
                this.loading = true;
                const {
                    sortBy,
                    sortDir: sortDesc,
                    page,
                    itemsPerPage
                } = this.options;
                let params = {};
                if (sortBy[0] !== undefined) {
                    params.sortBy = sortBy[0];
                }
                if (sortDesc !== undefined) {
                    params.sortDir = sortDesc ? 'asc' : 'desc';
                }
                if (page !== undefined) {
                    if (type === 'search') {
                        params.page = 1
                    } else {
                        params.page = page
                    }
                }
                if (itemsPerPage !== undefined) {
                    params.perPage = itemsPerPage;
                }
                if (this.storage) {
                    params.storage = this.storage;
                }
                if (this.date) {
                    params.date = this.date;
                }
                if (this.client) {
                    if (this.client.id) {
                        params.client = this.client.id
                    } else {
                        params.client = this.client
                    }
                }
                await this.$http
                    .get("admin/storage", {
                        params: params,
                    })
                    .then(res => {
                        this.storages = res.body.data
                        this.page = res.body.meta.current_page
                        this.totalStorages = res.body.meta.total
                        this.pageCount = res.body.meta.last_page
                    })
                    .catch(err => {
                        this.storages = []
                        this.totalStorages = 0
                        this.$toastr.error(this.$t('failed_to_get_list_storages'))
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            async getClients(str) {
                if (str) {
                    this.loadingClients = true
                    let params = {}
                    params.filter = 'search'
                    if (str !== 'undefined') {
                        params.client = str
                    }
                    await this.$http
                        .get("admin/client", {
                            params: params,
                        })
                        .then(res => {
                            this.clientItems = res.body.data
                        })
                        .catch(err => {
                            this.clientItems = []
                        })
                        .finally(end => {
                            this.loadingClients = false
                        })
                }
            },
            clearClients() {
                if (!this.client) {
                    this.clientItems = []
                }
            },
            redirectToClient(val) {
                if (val) {
                    this.$router.push({
                        name: 'client.profile',
                        params: {
                            id: val
                        }
                    })
                }
            },
        }
    }
</script>
